<i18n locale="th" lang="yaml" >
drone.create_service.label : "งานซ่อมใหม่"
drone.create_ticket.label : "เปิด Ticket"

drone_detail.note.title : "บันทึกช่วยจำ"
drone_detail.service.title : "งานซ่อม"
drone_detail.ticket.title : "Ticket"
drone_detail.note.lot.title : "บันทึกของ Lot {lot}"
drone_detail.histories.label : "ประวัติการแก้ไข"
drone_detail.parts.label : "รายการเปลี่ยนอะไหล่"
drone_detail.parts_list.title : "อะไหล่ปัจจุบัน"
</i18n>
<template>
	<div class="page">
		<div class="drone-section">
			<a-button type="dashed" @click="clickHistories">
				{{$t('drone_detail.histories.label')}}
			</a-button>
			<a-button type="dashed" @click="clickParts">
				{{$t('drone_detail.parts.label')}}
			</a-button>
		</div>
		<DroneConnectView
			v-if="canViewDroneConnect"
			:drone-data="droneData"/>

		<div class="drone-section">
			<div class="page-section-title">
				{{$t('drone_detail.note.title')}}
			</div>
			<div class="drone-section-content drone-notes-section">
				<DroneNoteList
					class="drone-notes-list"
					:can-add="canManageNote"
					:can-update="canManageNote"
					:drone="drone"/>
				<DroneLotNoteViewer
					class="drone-notes-lot"
					:editable="false"
					:topic="$t('drone_detail.note.lot.title',{ lot: droneData.lot.lot_name })"
					:lot-id="droneData.lot.id"
					:lot-name="droneData.lot.lot_name" />
			</div>
		</div>
		<div class="drone-section service-pane">
			<div class="page-section-title">
				{{$t('drone_detail.service.title')}}
				<div v-if="canCreateService" class="page-section-action">
					<a-button
						type="primary"
						@click="clickCreateService">
						<ServiceIcon class="anticon" />
						{{$t('drone.create_service.label')}}
					</a-button>
				</div>
			</div>
			<div class="drone-section-content">
				<ServiceSummaryPage
					class="page-padding-excluded"
					filter-name="drone_id"
					:filter-value="drone.id"
				  :recent-more-link="{ name: 'drone/service_recent',params: { id: drone.serial_no } }"/>
			</div>
		</div>
		<div class="drone-section ticket-pane">
			<div class="page-section-title">
				{{$t('drone_detail.ticket.title')}}
				<div v-if="canCreateTicket" class="page-section-action">
					<a-button
						type="primary"
						icon="tool"
						@click="clickCreateTicket">
						{{$t('drone.create_ticket.label')}}
					</a-button>
				</div>
			</div>
			<div class="drone-section-content">
				<TicketSummaryPage
					class="page-padding-excluded"
					filter-name="drone_id"
					:filter-value="drone.id"
					:progress-more-link="{ name: 'drone/ticket_progress',params: { id: drone.serial_no } }"
          :recent-more-link="{ name: 'drone/ticket_recent',params: { id: drone.serial_no } }"
          :pending-more-link="{ name: 'drone/ticket_pending',params: { id: drone.serial_no } }"/>
			</div>
		</div>

		<div v-if="$notEmpty(droneData.part_items)" class="drone-section part-pane">
			<div class="page-section-title">
				{{$t('drone_detail.parts_list.title')}}
			</div>
			<div class="drone-section-content">
				<DroneActivePartListView
					:drone-part-items="droneData.part_items"/>
			</div>
		</div>

	</div>
</template>

<script>
import get from "lodash/get"
import ServiceIcon from "vue-material-design-icons/HammerScrewdriver.vue"
import {emptyDrone} from "@utils/objectUtil"
import DroneNoteList from "@components/drone/DroneNoteList.vue"
import DroneLotNoteViewer from "@components/drone/DroneLotNoteViewer.vue"
import TicketSummaryPage from "@views/ticket/TicketSummaryPage.vue"
import ServiceSummaryPage from "@views/service/ServiceSummaryPage.vue"
import DroneConnectView from "@components/drone/DroneConnectView.vue"
import DroneActivePartListView from "@components/drone/DroneActivePartListView.vue"
export default {
	components : {
		ServiceIcon ,DroneNoteList,DroneLotNoteViewer ,
		TicketSummaryPage, ServiceSummaryPage, DroneConnectView,
		DroneActivePartListView,
	} ,
	props : {
		droneData : {
			type : null,
			default : emptyDrone()
		} ,
		isEnabledHgr : {
			type : Boolean,
			default : false,
		}
	} ,
	computed : {
		isDroneValid() {
			const status = get(this.droneData,'drone.status')
			return !this.$notEmpty(status) && status != 'decommission'
		} ,
		canCreateTicket() {
			return this.isDroneValid && this.$authorize('create', 'ticket')
		} ,
		canCreateService() {
			return this.isDroneValid && this.$authorize('create', 'service')
		} ,
		canManageNote() {
			return this.$authorize('update', 'drone');
		} ,
		drone() {
			return this.droneData.drone ? this.droneData.drone : {}
		} ,
		canViewDroneConnect() {
			return this.isDroneValid && this.isEnabledHgr && this.$can('changePolicy','drone')
		} ,
	} ,
	methods : {
		clickCreateService() {
			this.$open({name : 'drone/service_create' ,params : {serial_no : this.drone.serial_no}})
		} ,
		clickCreateTicket() {
			this.$open({name : 'drone/ticket_create' ,params : {serial_no : this.drone.serial_no}})
		} ,
		clickHistories() {
			this.$open({name : 'drone/histories' ,params : {serial_no : this.drone.serial_no}})
		} ,
		clickParts() {
			this.$open({name : 'drone/parts' ,params : {serial_no : this.drone.serial_no}})
		} ,
	}
}
</script>

<style lang="less" scoped>
.drone-section {
	background : @white;
	padding : 16px;
	border-radius : @border-radius-base;
	margin-bottom : 16px;
}

.drone-notes-section {
	display : flex;
	flex-wrap: wrap-reverse;
	align-items: flex-end;
	min-width: 0;
	margin-right : -16px;
	> div {
		flex-grow : 1;
		min-width : 275px;
		margin-bottom : 16px;
		margin-right : 16px;
	}

	.drone-notes-list::v-deep {
		.note-list {
			border : 1px solid @border-color-base;
		}
	}
}
.service-pane {
	background-image : url("@{image-dir}/bg_service.png");
	background-repeat: no-repeat;
  background-position: right top;
	background-size: 200px 200px;
	min-height : 250px;
}
.ticket-pane {
	background-image : url("@{image-dir}/bg_ticket.png");
	background-repeat: no-repeat;
  background-position: right top;
	background-size: 200px 200px;
	min-height : 250px;
}
</style>
