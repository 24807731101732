<i18n src="@i18n/drone/part.th.yaml" locale="th" lang="yaml"/>
<i18n locale="th" lang="yaml" >
drone_part.field.serial_no : "S/N"
drone_part.field.remark : 'หมายเหตุ'

drone_part.field.attach_type : "ติดตั้งจาก"
drone_part.field.serial_no.no_required : "ไม่มี S/N"
</i18n>

<template>
<a-table
	bordered class="mytable  mytable-scroll drone-part-item-table"
	:row-key="record => record.id"
	:data-source="dronePartItems"
	:pagination="false"
	size="small">
	<a-table-column
		align="center"
		:width="40">
		<template slot-scope="text,record,index">
			{{index + 1}}.
		</template>
	</a-table-column>
	<a-table-column
		:title="$t('part.field.sku')" data-index='part.sku'
		:width="150">
		<template slot-scope="text,record">
			<my-router-link name="part/view" :param-value="record.part_id" new-window auth-action="read" auth-module="part">
				{{text}}
			</my-router-link>
		</template>
	</a-table-column>
	<a-table-column
		:title="$t('common.table.column.detail')" data-index="part.name"
		class="col-detail">
		<template slot-scope="text,record">
			<MyPopover overlay-class-name="mycard-popover"
					:destroy-tooltip-on-hide="true">
				<template slot="content">
					<PartLiteCard :part="record.part" size="small"  link-target="_blank" />
				</template>
				{{record.part.name}}
			</MyPopover>
		</template>
	</a-table-column>
	<a-table-column
		:title="$t('drone_part.field.serial_no')"
		align="center"
		:width="200">
		<template slot-scope="record">
			<span v-if="record.part.has_serial_no">
				{{record.partItem.serial_no | emptyCheck}}
			</span>
			<span v-else>
				{{$t('drone_part.field.serial_no.no_required')}}
			</span>
		</template>
	</a-table-column>
	<a-table-column
		:title="$t('drone_part.field.attach_type')"
		:width="120">
		<template slot-scope="record">
			{{$tenum('drone.part.attached_type',record.attached_type)}}
			<MyTooltip
				:destroy-tooltip-on-hide="true">
				<div slot="title">
					{{record.attached_date | date}}
				</div>
				<a-icon type="clock-circle"/>
			</MyTooltip>
		</template>
	</a-table-column>

	<a-table-column
		:title="$t('drone_part.field.remark')"
		data-index="partItem.remark"
		:width="50"
		align="center">
		<template slot-scope="remark">
			<MyTooltip v-if="$notEmpty(remark)"
				:destroy-tooltip-on-hide="true">
				<div slot="title" v-trim class="multiline">
					{{remark}}
				</div>
				<a-icon type="message"/>
			</MyTooltip>
		</template>
	</a-table-column>

</a-table>

</template>

<script>
import {Table} from "ant-design-vue"
import MyTooltip from "@components/common/MyTooltip.vue"
import MyPopover from "@components/common/MyPopover.vue"
import PartLiteCard from "@components/part/PartLiteCard.vue"
export default {
	components : {
		PartLiteCard , MyPopover , MyTooltip,
		"a-table" : Table, "a-table-column" : Table.Column
	} ,
	props : {
		dronePartItems : {
			type : null,
			default : []
		}
	}
}

</script>
