<i18n locale="th" lang="yaml" >
card.progress_service.title : "งานซ่อมที่ค้างอยู่"
card.payment_service.title : "งานซ่อมค้างจ่าย"
card.recent_service.title : "งานซ่อมล่าสุด"

see_more.label : "@:common.see_more"
</i18n>
<template>
	<div class="page page-padding">
		<div class="service-section">
			<div class="service-section-header">
				<label>{{$t('card.progress_service.title')}}</label>
				<router-link to="/dashboard/in-progress" class="service-section-action">
					{{$t('see_more.label')}}
				</router-link>
			</div>
			<div class="service-section-content">
				<MyCardList
					:num-skeletons="3"
					:empty="progressServiceDataList.length === 0"
					class="mycard-list-grid service-progess-list"
					:loading="progressLoading">
						<ServiceLiteCard
							slot="skeleton"
							:loading="true" />
						<ServiceLiteCard v-for="serviceData of progressServiceDataList"
							:key="serviceData.id"
							:service-data="serviceData"/>
				</MyCardList>
			</div>
		</div>
		<template v-if="paymentServiceDataList.length > 0">
			<div class="service-section-divider" />
			<div class="service-section">
				<div class="service-section-header text-error">
					<label>{{$t('card.payment_service.title')}} <a-icon type="dollar"/></label>
					<router-link to="/dashboard/payment" class="service-section-action">
						{{$t('see_more.label')}}
					</router-link>
				</div>
				<div class="service-section-content">
					<MyCardList
						:num-skeletons="3"
						:empty="paymentServiceDataList.length === 0"
						class="mycard-list-grid service-progess-list"
						:loading="paymentLoading">
							<ServiceLiteCard
								slot="skeleton"
								:bordered="true"
								size="small"
								:loading="true" />
							<ServiceLiteCard v-for="serviceData of paymentServiceDataList"
								:key="serviceData.id"
								:service-data="serviceData"
								size="small"
								:bordered="true"
								:clickable="false"/>
					</MyCardList>
				</div>
			</div>
		</template>
		<div class="service-section-divider" />
		<div class="service-section">
			<div class="service-section-header">
				<label>{{$t('card.recent_service.title')}}</label>

				<router-link to="/dashboard/recent" class="service-section-action">
					{{$t('see_more.label')}}
				</router-link>
			</div>
			<div class="service-section-content">
				<MyCardList
					:num-skeletons="3"
					:empty="recentServiceDataList.length === 0"
					class="mycard-list-grid service-recent-list"
					:loading="progressLoading">
						<ServiceLiteCard
							slot="skeleton"
							:bordered="true"
							size="small"
							:loading="true" />
						<ServiceLiteCard v-for="serviceData of recentServiceDataList"
							:key="serviceData.id"
							:service-data="serviceData"
							size="small"
							:bordered="true"
							:clickable="false"/>
				</MyCardList>
			</div>
		</div>
	</div>
</template>
<script>
import MyCardList from "@components/common/MyCardList.vue"
import ServiceLiteCard from "@components/service/ServiceLiteCard.vue"
import axios from "axios"
import ApiError from "@utils/errors/ApiError"
import {fulfillServiceCardData} from "@utils/objectUtil"

export default {
	components :{
		MyCardList , ServiceLiteCard
	} ,
	props : {
		filterName : {
			type : String,
			default : undefined,
		} ,
		filterValue : {
			type : null,
			default : undefined,
		} ,
		recentMoreLink : {
			type : null,
			default : () => []
		} ,
	} ,
	data() {
		return {
			recentServiceDataList : [] ,
			recentLoading : false,

			progressServiceDataList : [] ,
			progressLoading : false,

			paymentServiceDataList : [] ,
			paymentLoading : false,
		}
	} ,
	watch : {
		filterName(){
			this.fetchData()
		} ,
		filterValue() {
			this.fetchData()
		} ,
	},
	mounted() {
		this.fetchData()
	} ,
	methods : {
		fetchData() {
			if (this.filterName && !this.filterValue) {
				return;
			}
			this.fetchRecentService()
			this.fetchProgressService()
			this.fetchPaymentService()
		} ,
		fetchRecentService() {
			this.recentLoading = true
			const search = {
				page_size : 5 ,
				current_page : 1,
			}
			const filter = {
				status_list : ['close'] ,
				payment_status_list : ['complete']
			}
			if (this.filterName) {
				filter[this.filterName] = this.filterValue
			}
			axios.get("/api/services/search",{params : {search,filter}}).then((response)=>{
				const bucket = response.data.data.bucket

				this.recentServiceDataList = response.data.data.services.map((s)=>{
					return fulfillServiceCardData(s,bucket)
				})
			}).catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error))
			}).finally(()=>{
				this.recentLoading = false
			})
		} ,
		fetchProgressService() {
			this.progressLoading = true
			const search = {
				page_size : 5 ,
				current_page : 1,
			}
			const filter = {
				mode : 'in-progress' ,
			}
			if (this.filterName) {
				filter[this.filterName] = this.filterValue
			}
			axios.get("/api/services/search",{params : {search,filter}}).then((response)=>{
				const bucket = response.data.data.bucket

				this.progressServiceDataList = response.data.data.services.map((s)=>{
					return fulfillServiceCardData(s,bucket)
				})
			}).catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error))
			}).finally(()=>{
				this.progressLoading = false
			})
		} ,
		fetchPaymentService() {
			this.paymentLoading = true
			const search = {
				page_size : 5 ,
				current_page : 1,
			}
			const filter = {
				status_list : ['close'] ,
				payment_status_list : ['new','in_progress']
			}
			if (this.filterName) {
				filter[this.filterName] = this.filterValue
			}
			axios.get("/api/services/search",{params : {search,filter}}).then((response)=>{
				const bucket = response.data.data.bucket

				this.paymentServiceDataList = response.data.data.services.map((s)=>{
					return fulfillServiceCardData(s,bucket)
				})
			}).catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error))
			}).finally(()=>{
				this.paymentLoading = false
			})
		}
	}
}
</script>
<style lang="less" scoped>
.service-section {
	margin-bottom : 24px;

	.mobile & {
		box-shadow : @shadow-2;
		margin : 0 auto 24px;
		max-width: 500px;
		padding : 8px 16px;
	}
}
.service-section-divider {
	border-bottom : 1px solid @border-color-base;
	margin-bottom : 24px;
	.mobile & {
		display : none;
	}
}
.service-section-header {
	padding-bottom : 4px;
	margin-bottom : 8px;
	label {
		font-family: @font-family-title;
		font-size : 1.2em;
		margin-right: 5px;
	}
	.service-section-action {
		text-decoration: underline dashed;
	}
}
.service-section-remark {
	font-size : 0.9em;
	color : @text-muted;
	margin-top : 8px;
}
</style>
